import React, { Fragment } from 'react';
import Section from './Section';
import styles from '../../styles/modules/common/banner.module.scss';
import { connect } from "react-redux";
import Image from "../common/Image";
import { isMobile } from "react-device-detect";
import { useDispatch} from "react-redux";
import { changeEdit, changeProgress } from '../../redux/actions/selfserveActions';
import { changeCartEdit } from '../../redux/actions/customerActions';
import { updateVerify } from '../../redux/actions/cartActions';
import { changeMyaccountEdit, setAddressPopupOpen } from '../../redux/actions/myaccountActions';
import { getIsAuthenticateCookie } from '../../api/cookies';
import { useRouter } from 'next/router';
import { blockSalesTouchPoint } from '../../utils/myaccount';


const PlainBanner = (props: any) => {
    const dispatch:any = useDispatch()
    const router:any = useRouter()
    const blockSalesPoint = blockSalesTouchPoint(router)
    const handleArrow =() =>{
        dispatch(changeProgress(7)),
        dispatch(changeEdit(false))
        dispatch(changeCartEdit(false))
        dispatch(updateVerify(false))
        dispatch(changeMyaccountEdit(false))
        dispatch(setAddressPopupOpen(false))
    }
    return (
        <Section className={`${styles.banner} ${props.className ? props.className : ''}`.trim()}>
            {
                props.breadcrumb && 
                <div className={`${styles.breadcrumb} container`}>
                    {
                        <Fragment>
                            {
                                isMobile && props.myaccount.is_AddressPopupOpen &&
                                <i><Image src={"/static/images/myaccount/Arrow_left.svg"} width="18px" height="18px" onClick={() => { handleArrow() }} /></i>
                            }
                            {blockSalesPoint ?
                                <>
                                    <a >Home</a>/ {props.path ? props.path : "Orders & Bookings"} {isMobile && props.myaccount.is_AddressPopupOpen && "/ Personal Details"}
                                </>
                                :
                                <>
                                    <a href="/">Home</a>/ {props.path ? props.path : "Orders & Bookings"} {isMobile && props.myaccount.is_AddressPopupOpen && "/ Personal Details"}
                                </>}
                        </Fragment>
                    }
                </div>
            }
            {props.children}
            <div className={`${styles._typography} container`}>
                {props.title && <h1>{props.title}</h1>}
                {props.subtitle && <p>{props.subtitle}</p>}
            </div>
        </Section>
    );
}

const mapStateToProps = (state: any) => {
    return {
      myaccount: state.myaccountReducer,
    };
  };
  
  export default connect(
    mapStateToProps
  )(PlainBanner);