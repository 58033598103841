import React, {  useEffect, useState } from 'react';
import Button from '../common/ui-components/input/Button';
import styles from '../../styles/modules/components/SelfServe/enter-address.module.scss';
import commonStyles from '../../styles/modules/components/SelfServe/phone-input-step.module.scss';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { changeEdit, changeProgress } from '../../redux/actions/selfserveActions';
import UITextField from '../common/ui-components/input/UITextField';
import LocationMap from '@/components/address/LocationMap';
import Image from '../common/Image'
import { getStaticPath,haversineDistance } from '../../utils';
import { CreateAddress, Serviceable, UpdateAddress } from '../../api/cart';
import { changeCartEdit, createNewAddress, updateAddress } from '../../redux/actions/customerActions';
import { SearchLocation } from '../../api/home';
import { GetPincodeDetails } from '../../api/selfserve';
import { updateVerify } from '../../redux/actions/cartActions';
import { cleverTapTrigger } from '../../tracking/segment';
import { disableModalEscapeClose, enableModalEscapeClose } from '../../utils/cookies';
import { changeMyaccountEdit, setAddressPopupOpen } from '../../redux/actions/myaccountActions';

interface ReduxStateProps {
    selfserveReducer: any,
    customer: any,
    myaccount: any
}

const AddAddress = (props: any) => {

    const [isFullScreen, setFullScreen] = useState<boolean>(false)
    const [pincode, setPincode] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [state, setState] = useState<string>("")
    const [landmark, setLandmark] = useState<string>("")
    const [address, setAddress] = useState<string>("")
    const [finalAddress, setFinalAddress] = useState<string>("")
    const [addressEnter, setEnterAddress] = useState<boolean>(false)
    const [latLng, setLatLng] = useState<any>({ lat: 19.10547002800624, lng: 72.8811771561344 })
    const [pincodeLatLng, setPincodeLatLng] = useState<any>({ lat: 19.10547002800624, lng: 72.8811771561344 })
    const [tempLatLng, setTempLatLng] = useState<any>({ lat: 19.10547002800624, lng: 72.8811771561344 })
    const [label, setLabel] = useState<string>("")
    const [nickname, setNickName] = useState<string>("Other")
    const [prev, setPrev] = useState<string>("")
    const [outOfBound, setOutOfBound] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>({ pincode: undefined, address: undefined, landmark: undefined, label: undefined })
    const [outOfPincode, setOutOfPincode] = useState<boolean>(false)
    const [enterEnabled, setEnterEnabled] = useState<boolean>(true)

    const labels = ["Home", "Office", "Other"]
    let filtered = labels.filter(item => !props.existing_label.includes(item));
    if(label!=="Other" && label!=="" && !filtered.includes(label)) filtered.push(label)
    if(prev!=="" && prev!=="Other" && !filtered.includes(prev)) filtered.push(prev)
    if(!filtered.includes("Other")) filtered.push("Other")
    filtered.sort()
    const dispatch:any = useDispatch()


    useEffect(() => {
        window.scroll({ behavior: 'smooth', left:0, top: 0 });
        setLabel(filtered[0])        
        if(props.isActivation && props.mode == "edit"){
            const {data} = props.data
            setPincode(props.data?.pincode)
            setCity(props.data?.city)
            setState(props.data?.state)
            setAddress(props.data?.address)
            setLandmark(props.data?.landmark)
            setLabel(props.data?.label)
            SearchLocation({ text: props.data?.address + ', '+ props.data?.pincode, type: 'geocode' })
            .then(resp => {
                if (resp) {
                    let lat = resp?.data?.results[0].geometry.location.lat;
                    let long = resp?.data?.results[0].geometry.location.lng;
                    setPincodeLatLng({ lat: lat, lng: long })          
                }
                })
                .catch((e)=>console.log(e))
        }
        if (props.selfserveReducer.edit === true || props.customer.edit === true || props.isKyc === true) {
            let data = props.selfserveReducer.edit === true ? props.selfserveReducer.customerDetails.address :
            props.isKyc === true ? props:
            props.customer.address.find(item => item.id === props.cart.edit_id)
            setPincode(data.pincode)
            setCity(data.city)
            setState(data.state)
            setAddress(data.address)
            setLandmark(data.landmark)
            setLabel(data.label)
            SearchLocation({ text: data.address + ', '+ data.pincode, type: 'geocode' })
            .then(resp => {
                if (resp) {
                    let lat = resp?.data?.results[0].geometry.location.lat;
                    let long = resp?.data?.results[0].geometry.location.lng;
                    setPincodeLatLng({ lat: lat, lng: long })          
                }
                })
                .catch((e)=>console.log(e))
            if(data.lat && data.lng)
            {
                setLatLng({ lat: Number(data.lat), lng: Number(data.lng) })
            setTempLatLng({ lat: Number(data.lat), lng: Number(data.lng) })
        }
        else{
            SearchLocation({ text: data.address + ', '+ data.pincode, type: 'geocode',source:"Address Management"  })
            .then(resp => {
                if (resp) {
                    let lat = resp?.data?.results[0]?.geometry.location.lat;
                    let long = resp?.data?.results[0]?.geometry.location.lng;
                    setLatLng({ lat: lat, lng: long })
                    setTempLatLng({ lat: lat, lng: long })
                }
            })
            .catch((e)=>console.log(e))
        }
        if (labels.includes(data.label) && data.label!=="Other") {
            setLabel(data.label)
        }
        else {
            setNickName(data.label || "Other")
            setLabel("Other")
        }
        if(!data.address)
        {
            setErrors({address:"Required field"})
        }
    }
    return () => {
        setPincode("")
        setCity("")
        setState("")
        setAddress("")
        setLandmark("")
        setLabel("")
        setLatLng({ lat: "", lng: "" })
        setTempLatLng({ lat: "", lng: "" })
    }
    }, [])


    const toggleFullScreen = (value) => {
        setFullScreen(value)
        value ? enableModalEscapeClose() : disableModalEscapeClose()
    }

    const checkServiceable = (pincode) => {
        if (props.isSelfserve) {
            const data = {
                "token_id": props.selfserveReducer.token_id,
                "osid": props.selfserveReducer.planDetails.osid,
                'pincode': pincode
            }
            GetPincodeDetails({
                "application": "website",
                "data": JSON.stringify(data)
            }).then((res: any) => {
                if (res && res.data) {
                    setCity(res.data.city)
                    setState(res.data.state)
                }
            })
        }
        else if (props.isMyAccount || props.isActivation) {
            // ToDo - Create New Api to city data based on pincode
            SearchLocation({ 
                text: pincode, 
                type: 'geocode', 
                source: "myaccount" 
            }).then((res: any) => {
                if (res && res.data) {
                    setCity(res.data.city)
                    setState(res.data.state)
                }
            })
        }
        else {
            Serviceable(props.cart.token_id, {
                'application': 'website',
                pincode: pincode
            }).then((res: any) => {
                if (res && res.data) {
                    // addCity(this.props.cart.type) // GTM TRIGGER - add City
                    // addState(this.props.cart.type) // GTM TRIGGER - add State
                    if (props.cart.items.filter(_item => !_item.serviceable).length) {
                        // this.setState({ pincode: "", city: "", state: "" })
                        setCity(res.data.city)
                        setState(res.data.state)
                    } else {
                        setCity(res.data.city)
                        setState(res.data.state)
                    }
                    if(res.data.city && res.data.state){
                        const a :any =document.querySelector(`input#address`)
                        a && a.focus()
                        a && a.scrollIntoView({behavior: 'smooth',block: "center"});
                        }
                }
            })
        }
    }

    const handlePincode = (e) => {

        if (!isNaN(e.target.value)) {
            setPincode(e.target.value.trim())

            if (e.target.value.length == 6) {
                // addPincode(this.props.cart.type) // GTM TRIGGER - add Pincode
                checkServiceable(e.target.value)
                let text = e.target.value;
                SearchLocation({ text: text, type: 'geocode', source:"Address Management" })
                    .then(resp => {
                        if (resp) {
                            let lat = resp?.data?.results[0].geometry.location.lat;
                            let long = resp?.data?.results[0].geometry.location.lng;
                            setLatLng({ lat: lat, lng: long })
                            setTempLatLng({ lat: lat, lng: long })
                            setPincodeLatLng({ lat: lat, lng: long })
                            const a :any =document.querySelector(`input#address`)
                            a && a.focus()
                            a && a.scrollIntoView({behavior: 'smooth', block: "center"});
                        }
                    })
                    .catch((e)=>{
                      console.log(e)  
                    })
            }
        }
    }

    const locationEnterAction = (value) => {
        if (value.length > 2) {
            let text = value +", " + pincode;
            SearchLocation({ text: text, type: 'geocode' , source:"Address Management"  })
                .then(resp => {
                    if (resp) {
                        let lat = resp?.data?.results[0].geometry.location.lat;
                        let long = resp?.data?.results[0].geometry.location.lng;
                        let add_comp = resp?.data?.results[0].address_components;
                        let postalCodeComp = (add_comp || []).filter(comp =>
                            comp.types.includes('postal_code'),
                          );
                        let cityCodeComp = (add_comp || []).filter(comp =>
                        comp.types.includes('locality'),
                        ) ;  
                        let dist = haversineDistance(lat,long,pincodeLatLng.lat,pincodeLatLng.lng)    
                        if(( postalCodeComp?.[0]?.long_name.slice(0,3)===pincode.slice(0,3)) || cityCodeComp && (cityCodeComp?.[0]?.long_name.trim().toLowerCase() === city.trim().toLocaleLowerCase()) || dist < 50000){
                            setLatLng({ lat: lat, lng: long })
                            setTempLatLng({ lat: lat, lng: long })
                            setEnterAddress(true);
                            setErrors({address:""})
                            setOutOfPincode(false)
                            setFinalAddress(address)
                            setEnterEnabled(false)
                            const a :any =document.querySelector(`input#landmark`)
                            a.focus()
                            a && a.scrollIntoView({behavior: 'smooth',block: "center"});
                        }
                        else
                        {
                            setOutOfPincode(true)
                            setTempLatLng({ lat: lat, lng: long })
                            setEnterAddress(true);
                            setEnterEnabled(false)
                            setErrors({address:"Address is outside of entered pincode"})
                        }
                    }
                })
        }
    }

    const cleverTap=(data,type)=>{
        cleverTapTrigger(" Address Popup Close",{pincode: data.pincode || '', city: data.city||'', address: data.address||'', type: type, value: "saved"})
    }
    const submitDetails = (type) => {
        
        let prev_data = props.selfserveReducer.edit === true ? props.selfserveReducer.customerDetails.address : props.customer.address.find(item => item.id === props.cart.edit_id)
        let temp = props.existing_label.filter(item => item!==null).map((item)=>{return item.toLowerCase()});
        if (address && pincode && state && city && label) {
            if (label === "Other" && nickname==="") {
                setErrors({ label: "Please add a nickname" })
            }
            else if (label === "Other" && nickname && nickname!=="" && temp.includes(nickname.trim().toLowerCase()) && nickname!==prev_data?.label) {
                setErrors({ label: "This nickname already exists" })
            }
            else if (addressEnter === false) {
                setErrors({ address: "Please click on enter" })
            }
            else {
                let data = {}
                data['address'] = address;
                data['landmark'] = landmark;
                data['label'] = label === "Other" ? nickname.trim() : label;
                data['lat'] = Number(tempLatLng.lat.toString().slice(0, 10));
                data['lng'] = Number(tempLatLng.lng.toString().slice(0, 10));
                data['customer_token'] = props.customer.customer_token;
                if (props.selfserveReducer.edit === true) {
                    UpdateAddress(data, props.selfserveReducer.customerDetails.address.id);
                    dispatch(updateAddress(data, props.selfserveReducer.customerDetails.address.id))
                    dispatch(changeProgress(7));
                }
                else if (props.customer.edit === true) {
                    UpdateAddress(data, props.cart.edit_id);
                    dispatch(updateAddress(data, props.cart.edit_id));
                    dispatch(changeProgress(7));
                }
                else {
                    data['pincode'] = pincode;
                    data['city'] = city;
                    data['state'] = state;
                    CreateAddress(data).then((res) => {
                        if (res) {
                            if(props.isMyAccount || props.Activation){
                                dispatch(changeMyaccountEdit(false))
                            }
                            dispatch(createNewAddress(res.data))
                            dispatch(changeProgress(7));
                        }
                    })
                }
                console.log(data)
                dispatch(changeEdit(false))
                dispatch(changeCartEdit(false))
                dispatch(updateVerify(false))
                cleverTap(data,type)
                
            }
        }
    }

    const settOutOfBound = (value) => {
        setOutOfBound(value)
    }

    const updateTempLatLong = (lat, long) => {
        setTempLatLng({ lat: lat, lng: long })
    }


    const infobox = <>
        <div className='popup-info'>
        <div className={(outOfBound || outOfPincode) ? 'error_desc map_desc' : 'map_desc'}>
                    {(outOfBound || outOfPincode)
                    ? 
                    <>
                        <p className='heading'>{outOfBound ? "Selected location is different from the entered address" : "Address is outside of entered pincode" }</p>
                        <p className='go_back' onClick={() => {updateTempLatLong(latLng.lat, latLng.lng); if(outOfPincode) { setOutOfPincode(false); setAddress(""); setErrors({"address":""});}}}>&lt; Go Back</p>
                    </> 
                    :
                    (((addressEnter && address)||props.selfserveReducer.edit === true || props.customer.edit === true?true:false) ?
                        <>
                            <p className='heading'>Place the pin accurately on the map</p>
                        </>
                        :
                        <>
                            <p className='heading'>Hi, we have spotted your location</p>
                        </>)
                }
            </div>
            <div className={'map_marker'}>
                <Image src={getStaticPath("/static/images/home/kyc_marker.png")} width={"16"} height={"36"} />
            </div>
            <div className={'map_loader'}>
                <div className={'circle'}></div>
                <div className={'circle'}></div>
            </div>
        </div>
    </>

    let data = props.selfserveReducer.edit === true ? props.selfserveReducer.customerDetails.address : props.customer.address.find(item => item.id === props.cart.edit_id)
    let type = ((props.selfserveReducer.edit === true || props.customer.edit === true) && !props.cart.verify ) ?"edit" : ((props.selfserveReducer.edit === true || props.customer.edit === true) && props.cart.verify ) ? "verify" :"new"
    return (
        <div className={`${styles.enter_address_parent} box_shadow ${isFullScreen ? styles.full_screened_map : ''}`}>
            { ((props.selfserveReducer.edit === true || props.customer.edit === true) && !props.cart.verify || props.mode ==="edit") ?
                <>
                    <h6>Edit Address</h6>
                    <p>Our Engineer will arrive at this location to provide the service</p>
                </>
                :
                ((props.selfserveReducer.edit === true || props.customer.edit === true) && props.cart.verify ) ?
                <>
                    <h6>Verify Service Address</h6>
                    <p>Our Engineer will arrive at this location to provide the service</p>
                </>
                :
                <>
                    <h6>Add Service Address</h6>
                    <p>Our Engineer will arrive at this location to provide the service</p>
                </>
            }
            <div className={styles.pincode_field}>
                <UITextField className={styles.pincode} placeholder="Enter your 6 digit pincode" label='Pincode' onChange={(e) => { handlePincode(e);setErrors({pincode:""}); }} isRequired value={pincode} isDisabled={props.selfserveReducer.edit === true || props.customer.edit === true || props.isKyc===true || props.mode ==="edit" } maxLength={6}  isAutoFocus={props.selfserveReducer.edit === true || props.customer.edit === true?false:true} propsError={errors.pincode} />
                <UITextField className={styles.state} placeholder="-" label='City/State' onChange={() => { }} value={`${city ? `${city}, ${state}` : ''}`} isDisabled />
            </div>
            <div className={`${styles.address_field_div} ${styles.address_div}`}>
                <UITextField className={styles.address_field} placeholder="Enter house/flat,area,locality" value={address} label='Address' onChange={(e) => { setAddress(e.target.value.trimStart()); address.length > 2 && setEnterEnabled(true) }} addressEnter={addressEnter} isRequired enterClick={locationEnterAction} propsError={errors.address} isEnter maxLength={200} isDisabled={!props.selfserveReducer.edit === true && !props.customer.edit === true && !(pincode.length==6)} isAutoFocus={props.selfserveReducer.edit === true || props.customer.edit === true?true:false} enterEnabled={enterEnabled} id="address"/>
            </div>
            <div className={styles.address_field_div}>
                <UITextField className={`${styles.address_field} ${styles.landmark_noleftpad}`} label='Landmark' value={landmark} onChange={(e) => { setLandmark(e.target.value) }} placeholder="Enter street/landmark" id="landmark"  onFocus={() =>{ enterEnabled && locationEnterAction(address); setEnterEnabled(false)}}/>
            </div>
            <div className={` ${styles.address_nickname} nickname`}>
                <p>Nickname:</p>
                {
                    filtered.map((item) => {
                        return <span className={item === label ? styles.nickname : ""} onClick={() => { setPrev(label); setLabel(item) }}>{item}</span>
                    })
                }
            </div>
            <div className={` ${styles.address_nickname} nickname`}>
                {
                    label === "Other" && <div className={styles.address_nickname_input}>
                            <div style={{"position":"relative", "display":"flex","width":"100%"}}>
                                <input className={styles.nickname_input} placeholder="Nickname Address" required value={nickname} onChange={(e) => {setErrors({label:""}); setNickName(e.target.value) }} id="nickname"/>
                                { nickname && <div className={styles.cross_button} onClick={()=>{setNickName("")} }></div> }
                            </div>
                            {errors.label && <p className={styles.textfield__error}>{errors.label} </p>}
                            </div>
                }
            </div>
            <div className={`${styles.map} map`}>
                <LocationMap toggleFullScreen={toggleFullScreen} draggable={pincode && (type !== "new" || addressEnter)} outOfPincode={outOfPincode} prevCoord={latLng} coord={tempLatLng} infobox={infobox} setOutOfBound={settOutOfBound} updateLatLong={updateTempLatLong} />
            </div>
            <div className={commonStyles.button_actions_sticky} data-varient='sticky'>
                {/* PREVIOUS BUTTON */}
                <Button
                    disabled={props.selfserveReducer.landed_on_page === 'address'}
                    className={commonStyles.cart_btn}
                    text={'Discard'}
                    variant='secondary'
                    onClick={() => {
                       if( props.isKyc === true || props.isActivation){
                        props.onDiscard()
                    } 
                       else{
                            dispatch(changeProgress(7)),
                            dispatch(changeEdit(false))
                            dispatch(changeCartEdit(false))
                            dispatch(updateVerify(false))
                            dispatch(changeMyaccountEdit(false))
                            dispatch(setAddressPopupOpen(false))
                            cleverTapTrigger(" Address Popup Close",{pincode: type !=="new"? data.pincode : '', city: type !=="new"?data.city:'', address: type !=="new"?data.address:'', type: type, value: "Not saved"})
                    }
                    }}
                />
                <Button
                    className={commonStyles.cart_btn}
                    text={'Save'}
                    variant='primary'
                    onClick={(e) => {
                    if(props.isKyc || props.isActivation)  {props.onSave(address,label,nickname,landmark,latLng,state, pincode, city)}
                    else {submitDetails(type)}}}
                    disabled={!pincode || !city || !state || !address || !label || !addressEnter || outOfBound || outOfPincode || finalAddress!==address || (label==="Other" && !nickname)}
                />
            </div>

        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        selfserveReducer: state.selfserveReducer,
        customer: state.customerReducer,
        cart: state.cart,
        myaccount: state.myaccountReducer,
    }
}

export default connect<ReduxStateProps>(mapStateToProps)(AddAddress);
