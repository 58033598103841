import React,{Fragment} from 'react';
import  styles from "../../../../styles/modules/ui-components/button.module.scss";

interface ButtonProps {
    text?:string,
    onClick:(e:React.MouseEvent)=> void,
    variant?:string | 'primary',
    inline?:boolean,
    style?:object,
    className?:string | null,
    children?: React.ReactNode,
    disabled?:boolean,
    href?: string | '#',
    target? : string | '',
    loading?:boolean
    thin?:boolean,
    icon?:boolean
}

const Button = (props:ButtonProps) => {
    let className = `${styles.app_btn} ${props.className ? props.className : ''}`.trim()
    className += ` ${props.thin && styles.thin}`
    return (
        <Fragment>
            <a role="button" href={props.href} target={props.target} style={props.style}  data-loading={props.loading ? props.loading : false} data-disabled={props.disabled} className={className} data-variant={props.variant ? props.variant : 'primary'} data-inline={props.inline} title={props.text} onClick={(e) => {
                if(props.href==='#' || !props.href){
                    e.preventDefault()
                    !props.disabled && !props.loading ? props.onClick(e) : null
                }else if(props.target ==="#"){
                    window.location.assign(props.href)    
                }else{
                    window.open(props.href,"_blank", "noopener,noreferrer")
                    window.opener = null
                    return true
                }
            }}>
                {
                    props.text? props.text : props.children
                }
            </a>
        </Fragment>
    );

}

export default Button;
