import React from 'react';
import parse from 'html-react-parser'

interface Props {
    title?:string,
    subtitle?:string,
    tagline?:string,
    className?: string,
    children: React.ReactNode,
    hasPadding?:boolean | true,
    dataBg?:string,  // for background values - shade-1 shade-2  - lower the lighter
    style?:object | {},
    ref?:any,
    id?:string,
    noTitleEL?:boolean,
    inverted?:boolean

}

const Section = (props:Props) => {
    const [disableEm, setDisableEm] = React.useState<boolean>(true)
    const { className , hasPadding , title , dataBg ,children ,style, subtitle, tagline , ref, id, noTitleEL, inverted  } = props;

    React.useEffect(()=>{
        if(title && title.length > 60){
            setDisableEm(false)
        }
    },[])

    return (
        <section className={`app-section ${className ? className : ''}`.trim()} id={id} ref={ref} data-padding={hasPadding} data-inverted={inverted} data-bg={dataBg ? props.dataBg : 'transparent'} style={{...style}}>
            <div className="container">
              { subtitle && <h2 className="app-section__subtitle">{ subtitle }</h2> }
              { title && <h3 className={`app-section__title ${!noTitleEL && disableEm ? 'em' : ""}`}>{parse(title)} </h3>}   
              {
                  tagline && <p className="app-section__tagline">{ tagline }</p>
              }
            </div>
            {
                children
            }
        </section>
    );
}

export default Section;
