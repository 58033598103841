import * as myaccountActionTypes from '../actionTypes/myaccountActionTypes'

export const setCurrentStepMyaccount = (data: any) => {
  console.log(data)
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.SET_CURRENT_STEP_MYACCOUNT,
      data: data,
    });
  };
};

export const getPlans = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.GET_PLANS,
      data: [...data],
    });
  };
};

export const setOrders = (data: any) => {
  return (dispatch) => {
      dispatch({
        type: myaccountActionTypes.GET_ORDERS,
        data: [...data]
      });
    };
  };

export const setMyRequestsDataRedux = (data: any) => {
  return (dispatch) => {
      dispatch({
        type: myaccountActionTypes.MY_REQUESTS_DATA,
        data: [...data],
      });
  };
};

export const nameEdit = () => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.EDIT_NAME,
    });
  };
};

export const emailEdit = () => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.EDIT_EMAIL,
    });
  };
};

export const setToastInfo = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.TOAST_INFO,
      data: data,
    });
  };
};

export const setLoader = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.LOADER,
      data: data,
    });
  };
};

export const changeMyaccountEdit = (data: boolean) => {
  return dispatch => {
    dispatch({
      type: myaccountActionTypes.UPDATE_MACCOUNT_EDIT_MODE,
      data,
    })
  }
}

export const deleteAddressPopup = (value, id) => {

  return dispatch => {
    dispatch({
      type: myaccountActionTypes.DELETE_ADDRESS_POPUP,
      isDeleteAddressPopup: value,
      deleteAddressId: id
    })
  }
}

export const setResendEmail = (value) => {

  return dispatch => {
    dispatch({
      type: myaccountActionTypes.EMAIL_RESEND_LINK,
      data: value
    })
  }
}

export const setAddressPopupOpen = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.IS_ADDRESSPOPUP_OPEN,
      data: data,
    });
  };
};

export const hasPlansRedux = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.HAS_PLANS,
      data: data,
    });
  };
};

export const setOrderItems = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.ORDER_ID,
      data: data,
    });
  };
};

export const setActivationPlans = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.SET_ACTIVATION_PLANS,
      data: data,
    });
  };
};

export const amazonOrderConfirmationPopup = (data:any) => {
  return (dispatch) => {
    dispatch({
      type: myaccountActionTypes.AMAZON_ORDER_CONFIRMATION,
      data: data,
    });
  };
};