import { getParamsObjectFromString } from '.';
import Cookies from 'js-cookie'

export const setSpecialCampaign = (config_data) => {
    const utm_campaign = getParamsObjectFromString(window.location.href).urlParams['utm_campaign'];
    // filter special campaign from config
    let special_campaign_config:any = []
    Object.keys(config_data).map((data, _index)=>{
        Object.keys(config_data[data]['category_types']).map((category:any, _index)=>{
            special_campaign_config.push(...config_data[data]['category_types'][category].allowed_campaigns)
        })
    })

    special_campaign_config = [...new Set(special_campaign_config)];
    // Checking Utm Campaign and update cookie
    if(utm_campaign){
        let campaign_cookie = Cookies.get('special_campaign') && JSON.parse(Cookies.get('special_campaign')) || []
        if(special_campaign_config.includes(utm_campaign)){
            Cookies.set('special_campaign', [...campaign_cookie,utm_campaign],  { expires: 365 })
        }
    }

    // Updating Existing Cookies Campaign based on config
    let campaign_cookie = Cookies.get('special_campaign') && JSON.parse(Cookies.get('special_campaign')) || []
    let new_campaign_cookie = campaign_cookie.filter(item=> special_campaign_config.includes(item))
    new_campaign_cookie = [...new Set(new_campaign_cookie)];
    Cookies.set('special_campaign', [...new_campaign_cookie],  { expires: 365 })
}

export const setAbExperimentSecond = (ab_experiment:any,cookie_expiry:30) => {
    const getSecondFromCookie = Cookies.get(ab_experiment)
    if(!getSecondFromCookie){
        const date = new Date();
        const seconds = date.getSeconds()
        Cookies.set(ab_experiment,seconds,{ expires: cookie_expiry })
    }
    return Cookies.get(ab_experiment)
}

export const isModalEscapeCloseDisabled = () => {
    return Cookies.get('isModalEscapeCloseDisabled') ? true : false
}
export const enableModalEscapeClose = () => {
    return Cookies.remove('isModalEscapeCloseDisabled')
}
export const disableModalEscapeClose = () => {
    return Cookies.set('isModalEscapeCloseDisabled', 1, { expires: 1 })
}

