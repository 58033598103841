import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react'
import Config from '../../../../config'
import { haversineDistance } from '../../../../utils';
import Image from "next/legacy/image";

interface SimpleMapProps {
    infobox?: any,
    coord: any,
    marker?: string,
    infocoord?: any,
    setOutOfBound: any,
    updateLatLong: any,
    prevCoord: any,
    outOfPincode: any,
    draggable?: any,
    toggleFullScreen?: any,
    isSRTracker?: boolean,
}




const LocationMap = (props: SimpleMapProps) => {
    const [isFullScreen, setFullScreen] = useState(false); 
    const { infobox, coord, prevCoord, outOfPincode, draggable, toggleFullScreen} = props;
    const handleMapClose = (event) => {
        if([27,8,13].includes(event.which)){
            setFullScreen(false)
            toggleFullScreen && toggleFullScreen(false)
            document.removeEventListener("keydown", handleMapClose, false);
        }
    }

    const initFullScreenMode = () => {
        if(!isFullScreen){
            document.addEventListener("keydown", handleMapClose, false);
        }
        else{
            document.removeEventListener("keydown", handleMapClose, false);
        }
        toggleFullScreen && toggleFullScreen(!isFullScreen)
        setFullScreen(!isFullScreen)
    }

    const FullScreenTrigger = () => <button onMouseUp={() => initFullScreenMode()} title = "Toggle fullscreen view" className = {`full-screen-trigger ${isFullScreen ? 'close-icon' : ''}`}> {
        isFullScreen ? 'x' : <Image
            src={"data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23111%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E"}
            width={18}
            height={18}
            style={{
                maxWidth: "100%",
                height: "auto"
            }} alt={'map'} />
    }</button>

    const initCoord = {lat: 19.105470028006238, lng: 72.88117715613441}
    return (
        <div className={`map ${isFullScreen && 'full-screened-map'}`}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: Config.GOOGLE_MAP_KEY }}
            center={coord}
            defaultZoom={18}
            onChange={({ center }) => {
                if(center.lat!==initCoord.lat && center.lng!==initCoord.lng && !outOfPincode)
                {                    
                    if (haversineDistance(prevCoord.lat, prevCoord.lng, center.lat, center.lng) > (props.isSRTracker ? 2000 : 500)) {
                        props.setOutOfBound(true);
                        props.updateLatLong(center.lat, center.lng)
                    }
                    else {
                        props.setOutOfBound(false);
                        props.updateLatLong(center.lat, center.lng)
                    }
                }
            }}
            options={{
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'on' }],
                    }
                ]
            }}
            draggable={!!draggable}
        />
        {infobox}
        <FullScreenTrigger />
        </div>

    )
}

export default React.memo(LocationMap)